.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}

.logo-text {
  fill: #3965FF;
}
.dark .logo-text {
  fill: white;
}